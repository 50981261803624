import {TPLPaginated} from "./tpl_paginated";
import {AjaxSync} from "../../shared/ajax_utilities";

class OperatorFinanceChangeWidget extends TPLPaginated {

    constructor() {
        super('operator_finance_change');

        window.addEventListener('AutoCompleteSelect', async (e) => {
            if(e.detail.tbl !== 'opr') {
                return;
            }

            app.DOM.content.find(`#${this.GRID_ID} #opr_finance_change_name`).css('background-color', '#fdebc8');
            this.CURRENT_PAGE = 0;
            this.EXTRA_DATA.operator_id = e.detail.data.value;
            await this.update();
        });

        window.addEventListener('AutoCompleteClear', async (_e) => {
            this.CURRENT_PAGE = 0;
            this.EXTRA_DATA = {};
            await this.update();
            app.DOM.content.find(`#${this.GRID_ID} #opr_finance_change_name`).css('background-color', 'field');
        });

        window.addEventListener('TplPaginatedRender',  async (_e) => {
            app.DOM.content.find(`#${this.GRID_ID} .btn-delete`).unbind().on('click', e => {
                const $btn = $(e.currentTarget);

                if( !confirm("Are you sure want to delete this Log entry?") ) {
                    return;
                }

                AjaxSync({
                    method: 'POST',
                    url: $btn.attr('data-href')
                },{
                    done: r => {
                        $btn.closest('tr').hide();
                    }
                });
            });
        });
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new OperatorFinanceChangeWidget();
    } else {
        console.warn('DashboardCore not initialised.');
    }
});